import { AnimatePresence, motion, Variants } from "framer-motion";

interface Props {
  isOpen: boolean;
  children: JSX.Element | JSX.Element[] | string | string[];
  variants?: Variants;
  height?: string;
}

const SlideOpen = ({ isOpen, children, variants, height = "360px" }: Props) => {
  const defaultVariants = {
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        opacity: { duration: 0.2 },
        height: { duration: 0.2, delay: 0.2 },
      },
    },
    open: {
      opacity: 1,
      height: height,
      transition: {
        opacity: { duration: 0.2, delay: 0.2 },
        height: { duration: 0.2 },
      },
    },
  };

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          key="details"
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          exit="closed"
          variants={variants ? variants : defaultVariants}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          className="slide-open"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SlideOpen;
