import Cookies from "js-cookie";
import { API_URL } from "../../settings";
import { API_COOKIE_NAME } from "../auth";
import {
  IActivateLeaguePayload,
  ICreateLeaguePayload,
  IEditLeagueDetailsPayload,
  IEditLeagueSchedulePayload,
  IJoinLeaguePayload,
  ILeaderboard,
  ILeagueDetails,
  ILeagueInvitesPayload,
  IRemoveLeaguePayload,
} from "../../types/interfaces/leagueTypes";
import { QueryClient } from "react-query";
import { QUERIES } from "../../constants/Queries";
import { IRolloverGroup, ISession, ISessionGroupMembership } from "../../types/interfaces/authInterTypes";

export const createLeagueApi = async (payload: ICreateLeaguePayload): Promise<ILeagueDetails> => {
  const url = `
      ${API_URL}/v1/pgaroster/groups.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  // console.log(token, payload);
  // console.log(payload, "payload");
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (res.status !== 200) {
        if (response.errors.name.includes("TAKEN")) {
          reject(new Error("Sorry that league name is taken"));
        }
        if (response.errors.name.includes("TOO_SHORT")) {
          reject(new Error("Sorry that league name is too short"));
        }
        reject(new Error("Sorry something went wrong"));
      } else {
        reject(new Error("Sorry something went wrong"));
      }
    }),
  );
};

export const joinLeagueApi = async (payload: IJoinLeaguePayload, providedToken?: string): Promise<ILeagueDetails> => {
  const url = `
      ${API_URL}/v1/pgaroster/groups/${payload?.group?.group_id}/join.json`;
  const token = providedToken ? providedToken : Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify(payload.payload),
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (res.status === 422 && response.errors.password) {
        reject(new Error(response.errors.password));
      }
      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong while joining that league"));
      } else {
        reject(new Error("Sorry something went wrong while joining that league"));
      }
    }),
  );
};

export const deleteLeagueApi = async (payload: number): Promise<void> => {
  const url = `
  ${API_URL}/v1/pgaroster/groups/${payload}.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
    }).then(async (res) => {
      if (res.status === 200) {
        resolve();
      }
      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong"));
      } else {
        reject(new Error("Sorry something went wrong"));
      }
    }),
  );
};

export const editLeagueDetailsApi = async (payload: IEditLeagueDetailsPayload): Promise<ILeagueDetails> => {
  const url = `
  ${API_URL}/v1/pgaroster/groups/${payload.league_id}.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  // console.log(token, payload);
  // console.log(payload, "payload");
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (res.status !== 200) {
        if (response.errors.name.includes("TAKEN")) {
          reject(new Error("Sorry that league name is taken"));
        }
        if (response.errors.name.includes("TOO_SHORT")) {
          reject(new Error("Sorry that league name is too short"));
        }
        reject(new Error("Sorry something went wrong"));
      } else {
        reject(new Error("Sorry something went wrong"));
      }
    }),
  );
};

export const editLeagueScheduleApi = async (payload: IEditLeagueSchedulePayload): Promise<ILeagueDetails> => {
  const url = `
  ${API_URL}/v1/pgaroster/groups/${payload.league_id}.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  // console.log(token, payload);
  // console.log(payload, "payload");
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify({ entry_id: payload.entry_id, tournament_ids: payload.tournament_ids }),
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (res.status !== 200) {
        if (response.errors.name.includes("TAKEN")) {
          reject(new Error("Sorry that league name is taken"));
        }
        if (response.errors.name.includes("TOO_SHORT")) {
          reject(new Error("Sorry that league name is too short"));
        }
        reject(new Error("Sorry something went wrong"));
      } else {
        reject(new Error("Sorry something went wrong"));
      }
    }),
  );
};

// Member leaves a league by removing own entry.
export const leaveLeagueApi = async (payload: IJoinLeaguePayload): Promise<void> => {
  const url = `
      ${API_URL}/v1/pgaroster/groups/${payload?.group?.group_id}/leave.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify(payload.payload),
    }).then(async (res) => {
      if (res.status === 200) {
        resolve();
      }

      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong while leaving that league"));
      } else {
        reject(new Error("Sorry something went wrong while leaving that league"));
      }
    }),
  );
};

// Commissioner/creator removes an member/entry from a league
export const removeLeagueApi = async (payload: IRemoveLeaguePayload): Promise<void> => {
  const url = `
    ${API_URL}/v1/pgaroster/groups/${payload.group_id}/remove_membership.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify({ entry_id: payload.entry_id }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve();
        }

        if (res.status !== 200) {
          reject(new Error("Sorry something went wrong while removing that entry from the league"));
        } else {
          reject(new Error("Sorry something went wrong while removing that entry from the league"));
        }
      })
      .catch((err) => {
        reject(err);
      }),
  );
};

export const optimisticCreateGroup = (queryClient: QueryClient, league: ILeagueDetails) => {
  queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession): ISession => {
    if (oldSession.pgaroster && oldSession.pgaroster.entry?.name) {
      const userMembership = oldSession?.pgaroster?.entry?.group_memberships.find(
        (g) => g.group_id === league.group_id,
      );
      if (!userMembership) {
        // If use is not already a member push unranked membership object to their group membership array
        const entryObject: ISessionGroupMembership = {
          entry_id: oldSession.pgaroster.entry?.entry_id,
          name: oldSession.pgaroster.entry?.name,
          group_id: league.group_id,
          is_default: false,
          tournament_points: oldSession.pgaroster.entry?.tournament_points,
          tournament_rank: 999999999,
          segment_points: 0,
          segment_rank: 999999999,
          season_points: 0,
          season_rank: 999999999,
          current_picks: null,
          email: null,
          join_token: null,
        };
        oldSession.pgaroster.entry?.group_memberships.push(entryObject);
        // console.log(entryObject, "Entry pushed [Leagues.ts]");
      }
      oldSession.pgaroster.entry?.groups.push(league);
      // console.log(oldSession, userMembership, "userMembership [Leagues.ts]");
    }
    return oldSession;
  });
};

export const optimisticEditGroup = (queryClient: QueryClient, league: ILeagueDetails) => {
  queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession): ISession => {
    if (oldSession.pgaroster && oldSession.pgaroster.entry?.name) {
      const userMembership = oldSession?.pgaroster?.entry?.groups?.findIndex((g) => g.group_id === league.group_id);
      if (userMembership !== -1) {
        oldSession.pgaroster.entry.groups[userMembership] = league;
      }
    }
    return oldSession;
  });
  queryClient.setQueryData([QUERIES.LEAGUE_DETAILS, league?.group_id], (oldLeague: any): ILeagueDetails => {
    oldLeague = league;

    return oldLeague;
  });
};

export const optimisticJoinGroup = (queryClient: QueryClient, league: ILeagueDetails) => {
  queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession): ISession => {
    if (oldSession.pgaroster && oldSession.pgaroster.entry?.name) {
      const userMembership = oldSession?.pgaroster?.entry?.group_memberships?.find(
        (g) => g.group_id === league.group_id,
      );
      if (!userMembership) {
        // If use is not already a member push unranked membership object to their group membership array
        const entryObject: ISessionGroupMembership = {
          entry_id: oldSession.pgaroster.entry?.entry_id,
          name: oldSession.pgaroster.entry?.name,
          group_id: league.group_id,
          is_default: false,
          tournament_points: oldSession.pgaroster.entry?.tournament_points,
          tournament_rank: 999999999,
          segment_points: 0,
          segment_rank: 999999999,
          season_points: 0,
          season_rank: 999999999,
          current_picks: null,
          email: null,
          join_token: null,
        };

        if (oldSession.pgaroster.entry?.group_memberships?.length) {
          oldSession.pgaroster.entry?.group_memberships?.push(entryObject);
          oldSession.pgaroster.entry?.groups.push(league);
        } else {
          oldSession.pgaroster.entry.group_memberships = [entryObject];
          oldSession.pgaroster.entry.groups = [league];
        }
        // console.log(entryObject, "Entry pushed [Leagues.ts]");
      }
      // console.log(oldSession, userMembership, "userMembership [Leagues.ts]");
    }
    return oldSession;
  });
};

export const optimisticActivateGroup = (queryClient: QueryClient, league: ILeagueDetails) => {
  queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession): ISession => {
    if (oldSession.pgaroster && oldSession.pgaroster.entry?.name) {
      const userMembership = oldSession?.pgaroster?.entry?.group_memberships.find(
        (g) => g.group_id === league.group_id,
      );
      if (!userMembership) {
        // If use is not already a member push unranked membership object to their group membership array
        const entryObject: ISessionGroupMembership = {
          entry_id: oldSession.pgaroster.entry?.entry_id,
          name: oldSession.pgaroster.entry?.name,
          group_id: league.group_id,
          is_default: false,
          tournament_points: oldSession.pgaroster.entry?.tournament_points,
          tournament_rank: 999999999,
          segment_points: 0,
          segment_rank: 999999999,
          season_points: 0,
          season_rank: 999999999,
          current_picks: null,
          email: null,
          join_token: null,
        };
        oldSession.pgaroster.entry?.group_memberships.push(entryObject);
        // console.log(entryObject, "Entry pushed [Leagues.ts]");
      }
      oldSession.pgaroster.entry?.groups.push(league);

      oldSession.pgaroster.rollover_groups = oldSession.pgaroster.rollover_groups?.filter(
        (g) => g.name !== league.name,
      );
      // console.log(oldSession, userMembership, "userMembership [Leagues.ts]");
    }
    return oldSession;
  });
};

export const optimisticRetireGroup = (queryClient: QueryClient, league: IRolloverGroup | null) => {
  queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession): ISession => {
    if (oldSession.pgaroster && oldSession.pgaroster.entry?.name && league) {
      oldSession.pgaroster.rollover_groups = oldSession.pgaroster.rollover_groups?.filter(
        (g) => g.name !== league.name,
      );
    }
    return oldSession;
  });
};

export const optimisticDeleteGroup = (queryClient: QueryClient, league: ILeagueDetails | null | undefined) => {
  queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession): ISession => {
    if (oldSession.pgaroster && oldSession.pgaroster.entry?.name && league) {
      oldSession.pgaroster.entry.groups = oldSession.pgaroster.entry.groups?.filter(
        (g) => g.group_id !== league.group_id,
      );
    }
    return oldSession;
  });
};

export const optimisticUpdateDefaultLeague = (queryClient: QueryClient, leagueId: number) => {
  queryClient.setQueryData(QUERIES.SESSION, (oldSession: ISession): ISession => {
    if (oldSession.pgaroster && oldSession.pgaroster.entry?.name && leagueId) {
      const group = oldSession.pgaroster.entry.group_memberships?.findIndex((g) => g.group_id === leagueId);
      const prevDefault = oldSession.pgaroster.entry.group_memberships?.findIndex((g) => g.is_default === true);
      if (group !== -1) {
        oldSession.pgaroster.entry.group_memberships[group].is_default = true;
      }
      if (prevDefault !== -1) {
        oldSession.pgaroster.entry.group_memberships[prevDefault].is_default = false;
      }
    }
    return oldSession;
  });
};

export const createDebugRollover = () => {
  const url = `
  ${API_URL}/v1/pgaroster/rollover-group/create.json`;
  const token = Cookies.get(API_COOKIE_NAME);
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify({ name: "My testing league 10", emails: ["victor.iannarella@sharplink.com"] }),
    }).then(async (res) => {
      if (res.status === 200) {
        resolve(true);
      }

      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong while leaving that league"));
      } else {
        reject(new Error("Sorry something went wrong while leaving that league"));
      }
    }),
  );
};

export const activateRolloverLeague = (payload: IActivateLeaguePayload): Promise<ILeagueDetails> => {
  const url = `
      ${API_URL}/v1/pgaroster/rollover-group/restore.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (res.status === 422 && response.errors.password) {
        reject(new Error(response.errors.password));
      }
      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong while joining that league"));
      } else {
        reject(new Error("Sorry something went wrong while joining that league"));
      }
    }),
  );
};

export const retireRolloverLeague = (payload: IActivateLeaguePayload): Promise<void> => {
  const url = `
      ${API_URL}/v1/pgaroster/rollover-group/delete.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
      if (res.status === 200) {
        resolve();
      }
      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong while retiring that league"));
      } else {
        reject(new Error("Sorry something went wrong while retiring that league"));
      }
    }),
  );
};

export const sendLeagueInvites = (payload: ILeagueInvitesPayload): Promise<void> => {
  const url = `
      ${API_URL}/v1/pgaroster/groups/${payload.league_id}/invite.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify({ entry_id: payload.entry_id, emails: payload.emails }),
    }).then(async (res) => {
      if (res.status === 200) {
        resolve();
      }
      if (res.status === 422) {
        reject();
      }
      if (res.status !== 200) {
        reject(new Error("Sorry something went wrong while sending invites"));
      } else {
        reject(new Error("Sorry something went wrong while sending invites"));
      }
    }),
  );
};

export const fetchMember = (
  leagueId: number,
  tournamentId: number,
  search: string,
  isCurrent: boolean,
): Promise<ILeaderboard> => {
  const url = isCurrent
    ? `${API_URL}/${search ? "v1" : "static-v1"}/pgaroster/groups/${leagueId}/tournament/page/1.json${
        search ? `?search=${search}` : ""
      }`
    : `
  ${API_URL}/${
        search ? "v1" : "static-v1"
      }/pgaroster/groups/${leagueId}/historical/tournament/${tournamentId}/page/1.json${
        search ? `?search=${search}` : ""
      }`;
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        // TODO better error handling
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

export const updateDefaultLeague = (leagueId: number, entryId: number): Promise<void> => {
  const url = `${API_URL}/v1/pgaroster/groups/${leagueId}/default.json`;
  const token = Cookies.get(API_COOKIE_NAME);

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token || "",
      },
      body: JSON.stringify({ entry_id: entryId }),
    }).then(async (res) => {
      if (res.status === 200) {
        resolve();
      }

      if (res.status >= 400) {
        // TODO better error handling
        reject("Failed to set default league");
      } else {
        reject();
      }
    }),
  );
};
